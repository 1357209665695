import { Paper } from "@material-ui/core"
import { useEmblaCarousel } from "embla-carousel/react"
import React, { useEffect } from "react"
import styled from "styled-components"
import { borderRadius } from "../../theme/globalStyles"

interface IProps {
  slides: JSX.Element[]
}

const OuterCarousel = styled.div`
  padding: ${({ theme }) => theme.spacing(4)}px;
  background-color: transparent;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding: 0;
  }
`

const CarouselWrapper = styled(Paper)`
  overflow: hidden;
  ${borderRadius};
`

const Container = styled.div`
  display: flex;
`

const Slide = styled.div`
  position: relative;
  min-width: 100%;
`

const Carousel: React.FC<IProps> = ({ slides }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })

  useEffect(() => {
    if (emblaApi) {
      const interval = setInterval(() => {
        if (emblaApi.canScrollNext()) {
          emblaApi.scrollNext()
        }
      }, 8000)

      return () => clearInterval(interval)
    }
  }, [emblaApi])

  return (
    <OuterCarousel>
      <CarouselWrapper ref={emblaRef}>
        <Container>
          {slides.map((slide, i) => (
            <Slide key={i} children={slide} />
          ))}
        </Container>
      </CarouselWrapper>
    </OuterCarousel>
  )
}

export default Carousel
