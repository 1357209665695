import Paper, { PaperProps } from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Img, { FluidObject } from "gatsby-image"
import React from "react"
import styled from "styled-components"
import {
  HomePageQuery_wpPage_homeFields_sectionone,
  HomePageQuery_wpPage_homeFields_sectiontwo,
} from "../../pages/__generated__/HomePageQuery"
import WpContent from "../base/layout/wpContent"

interface SectionProps extends WrapperProps {
  section:
    | HomePageQuery_wpPage_homeFields_sectionone
    | HomePageQuery_wpPage_homeFields_sectiontwo
    | null
    | undefined
}

interface WrapperProps {
  direction: "row" | "row-reverse"
}

interface TextWrapperProps {
  left: boolean
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  flex-direction: ${({ direction }) => direction};
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    flex-direction: column;
  }
`

const ImgWrapper = styled(Paper)`
  width: 100%;
  max-width: 500px;
  overflow: hidden;
`

const TextWrapper = styled(({ left, ...props }) => <Paper {...props} />)<
  TextWrapperProps & PaperProps
>`
  padding: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(6)}px`};
  flex: 1;
  min-width: 300px;
  z-index: 100;

  margin-left: ${({ left }) => (left ? -55 : 0)}px;
  margin-right: ${({ left }) => (left ? 0 : -55)}px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding-top: 65px;
    z-index: -1;
    margin-top: -55px;
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: ${({ theme }) =>
      `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(6)}px`};
    padding-top: 65px;
  }
`

const Section: React.FC<SectionProps> = ({ section, direction }) => {
  if (!section) return null

  const { fluid } = { ...section.image?.localFile?.childImageSharp }
  const left = direction === "row"

  if (!fluid || !section.text) return null

  return (
    <Wrapper direction={direction}>
      <ImgWrapper>
        <Img fluid={fluid as FluidObject} />
      </ImgWrapper>

      <TextWrapper left={left}>
        <Typography
          component={WpContent}
          dangerouslySetInnerHTML={{ __html: section.text }}
        />
      </TextWrapper>
    </Wrapper>
  )
}

export default Section
